<template>
  <div :class="$style.searchRecordsList">
    <template v-for="(record, index) of formattedRecords">
      <jtn-ui-search-archive-item
          :key="record.id"
          :url-title="removeTags(record.header)"
          :url="record.urls.url"
          :target-url="getTargetLink(record.urls.url)"
          :header="record.header"
          :subheader="record.subheader"
          :image="record.mainPhoto ? record.mainPhoto.previewImage : null"
          :is-mobile-only="isMobileOnly"
          :has-video-mark="record.hasVideoMark"
          @click="onClickArchiveItem(record)"
      >
        <template v-slot:format>
          <jtn-ui-typography
              v-if="record.formats.length > 0"
              :type="''"
              v-bind="formatComponent(record.formats[0])"
              @click="onClickFormat(record.formats[0])"
          >
            {{ record.formats[0].name }}
          </jtn-ui-typography>
        </template>

        <template v-slot:theme>
          <jtn-ui-typography
              v-if="record.themes.length > 0 && hasTheme"
              tag="a"
              :type="''"
              :href="record.themes[0].path"
              :title="record.themes[0].name"
              @click="onClickTheme(record.themes[0])"
          >
            {{ record.themes[0].name }}
          </jtn-ui-typography>
        </template>

        <template v-slot:rubrics>
          <jtn-ui-typography
              v-for="rubric of rubrics(record)"
              slot="rubrics"
              :key="rubric.path"
              tag="a"
              :type="''"
              :href="rubric.path"
              :title="rubric.name"
              @click="onClickRubric(rubric)"
          >
            {{ rubric.name }}
          </jtn-ui-typography>
        </template>

        <template v-slot:stats>
          <jtn-ui-record-statistics-will-deprecate
              slot="stats"
              :views="record.viewsCount"
              :comments="record.commentsCount"
              :comments-url="record.urls.urlComments"
              :comments-enabled="record.isCommentsAllowed"
              :discuss-text="$_RecordItemUtilsMixin_discussText"
              :discuss-url="$_RecordItemUtilsMixin_getDiscussUrl(record.urls.urlComments)"
              :target-url="getTargetLink(record.urls.urlComments)"
              @discuss="goToDiscuss($event)"
              @commentsClicked="onClickComments(record)"
          />
        </template>

        <template v-slot:date>
          <date-info
              slot="date"
              :date="record.publishAt"
              :region-id="regionId"
              :is-with-link="true"
          />
        </template>

        <jtn-ui-bookmark
            slot="bookmark"
            :class="$style.bookmark"
            :is-added="checkIfInBookmarks(record.id)"
            @clickedBookmark="changeBookmarkStatus(record.id)"
        />
      </jtn-ui-search-archive-item>

      <component
          v-if="index === 6 && betweenArchiveAdvBlock1"
          v-bind="bindAdvComponent"
          :key="betweenArchiveAdvBlock1.data"
          :adv="betweenArchiveAdvBlock1"
      />
      <component
        v-if="index === 6 && betweenArchiveMobileAdvBlock1"
        v-bind="bindAdvComponent"
        :key="betweenArchiveMobileAdvBlock1.data"
        :adv="betweenArchiveMobileAdvBlock1"
      />

      <component
          v-if="index === 13 && betweenArchiveAdvBlock2"
          v-bind="bindAdvComponent"
          :key="betweenArchiveAdvBlock2.data"
          :adv="betweenArchiveAdvBlock2"
      />
      <component
        v-if="index === 13 && betweenArchiveMobileAdvBlock2"
        v-bind="bindAdvComponent"
        :key="betweenArchiveMobileAdvBlock2.data"
        :adv="betweenArchiveMobileAdvBlock2"
      />

      <component
          v-if="index === 20 && betweenArchiveAdvBlock3"
          v-bind="bindAdvComponent"
          :key="betweenArchiveAdvBlock3.data"
          :adv="betweenArchiveAdvBlock3"
      />
      <component
        v-if="index === 20 && betweenArchiveMobileAdvBlock3"
        v-bind="bindAdvComponent"
        :key="betweenArchiveMobileAdvBlock3.data"
        :adv="betweenArchiveMobileAdvBlock3"
      />

    </template>

    <bookmark-error-modal
        v-if="isBookmarkErrorModalRendered"
        v-model="isBookmarkErrorModalOpened"
    />
    <something-wrong-modal
        v-if="isSomethingWrongModalRendered"
        v-model="isSomethingWrongModalOpen"
    />

    <BlockedUserModal
        v-if="isBlockedUserModalRendered"
        v-model="isBlockedUserModalOpened"
    />

    <auth-and-register-modal
        v-if="isAuthModalRendered"
        v-model="isAuthModalOpen"
    />
  </div>
</template>

<script>
import { createArchiveItemImage } from '@jtnews/shared/news';
import { guardUnspecified } from '@portal/utils/util-guards';
import { isAbsoluteUrl } from '@portal/utils/util-url';
import { JtnUiRecordStatisticsWillDeprecate } from '@jtn-ui/components/record';
import {
  JtnUiTypography,
  JtnUiSearchArchiveItem,
  JtnUiBookmark
} from '@jtnews/jtn-ui';
import { DateInfo } from '../../../shared/components/date-info';

import { StoreMixin } from '../../../shared/mixins/store';
import { RecordItemUtilsMixin } from '../../../shared/mixins';
import { Adv, AdvCreative } from '../../../shared/components/_adv';
import { ScrollBlockAnalytics } from '../../../shared/mixins/scroll-block-analycs.mixin';

// eslint-disable-next-line @typescript-eslint/naming-convention
const SomethingWrongModal = () =>
    import(/* webpackChunkName: "something-wrong-modal" */ '@jtnews/layout/modals').then(m => m.SomethingWrongModal);

// eslint-disable-next-line @typescript-eslint/naming-convention
const BookmarkErrorModal = () =>
    import(/* webpackChunkName: "bookmark-error-modal" */ '@jtnews/layout/modals').then(m => m.BookmarkErrorModal);

// eslint-disable-next-line @typescript-eslint/naming-convention
const BlockedUserModal = () =>
    import(/* webpackChunkName: "blocked-user-modal" */ '@jtnews/layout/modals').then(
        m => m.BlockedUserModal
    );

// eslint-disable-next-line @typescript-eslint/naming-convention
const AuthAndRegisterModal = () =>
    import(/* webpackChunkName: "auth-and-register-modal" */ '@jtnews/layout/modals').then(
        m => m.AuthAndRegisterModal
    );
export default {
  name: 'SearchResultsList',
  components: {
    JtnUiSearchArchiveItem,
    JtnUiRecordStatisticsWillDeprecate,
    JtnUiTypography,
    JtnUiBookmark,
    DateInfo,
    Adv,
    AdvCreative,
    AuthAndRegisterModal,
    BookmarkErrorModal,
    SomethingWrongModal,
    BlockedUserModal
  },
  mixins: [RecordItemUtilsMixin, StoreMixin, ScrollBlockAnalytics],
  props: {
    records: {
      type: Array,
      default() {
        return [];
      }
    },
    hasTheme: {
      type: Boolean,
      default: true
    },
    params: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      scrollTargets: [
        { value: 25, goalName: 'scrollAllNews25' },
        { value: 50, goalName: 'scrollAllNews50' },
        { value: 75, goalName: 'scrollAllNews75' },
        { value: 100, goalName: 'scrollAllNews100' }
      ],
      sendScrollTargets: [],
      isBookmarkProcessing: false,
      isBookmarkErrorModalOpened: false,
      isBookmarkErrorModalRendered: false,
      isSomethingWrongModalOpen: false,
      isSomethingWrongModalRendered: false,
      isBlockedUserModalOpened: false,
      isBlockedUserModalRendered: false,
      isAuthModalOpen: false,
      isAuthModalRendered: false
    };
  },
  computed: {
    betweenArchiveAdvBlock1() {
      return this.isScroogeTest ? this.store.advModule.betweenArchiveDesktopAdvBlock1 : this.store.advModule.betweenArchiveAdvBlock1;
    },
    betweenArchiveAdvBlock2() {
      return this.isScroogeTest ? this.store.advModule.betweenArchiveDesktopAdvBlock2 : this.store.advModule.betweenArchiveAdvBlock2;
    },
    betweenArchiveAdvBlock3() {
      return this.isScroogeTest ? this.store.advModule.betweenArchiveDesktopAdvBlock3 : this.store.advModule.betweenArchiveAdvBlock3;
    },
    betweenArchiveMobileAdvBlock1() {
      return this.store.advModule.betweenArchiveMobileAdvBlock1;
    },
    betweenArchiveMobileAdvBlock2() {
      return this.store.advModule.betweenArchiveMobileAdvBlock2;
    },
    betweenArchiveMobileAdvBlock3() {
      return this.store.advModule.betweenArchiveMobileAdvBlock3;
    },
    regionId() {
      return this.store.regionId;
    },
    isBlockedUser() {
      return this.store.commonModule.isBlockedUser;
    },
    userInfo() {
      return this.store.commonModule.userInfo || null;
    },
    isBookmarkError() {
      return this.store.userModule.isBookmarkLimitError;
    },
    isMobileOnly() {
      return this.store.deviceInfo.isMobile && !this.store.deviceInfo.isTablet;
    },
    formattedRecords() {
      return this.records.map(record => (
        {
          ...record,
          mainPhoto: guardUnspecified(record.mainPhoto) ? createArchiveItemImage(record.mainPhoto) : null
        }
      ));
    },
    isScroogeTest() {
      return this.store.layoutModule.isScroogeTest;
    },
    bindAdvComponent() {
      return {
        is: this.isScroogeTest ? 'AdvCreative' : 'Adv'
      };
    }
  },
  mounted() {
    this.sendReachGoal('Скроллинг', 'Открыл', 'viewAllNews');
    this.checkScrollElement = this.$el;
    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll, false);
  },
  methods: {
    removeTags(value) {
      return value.replace(/(\<(\/?[^>]+)>)/g, '');
    },
    formatNumber(number) {
      return number.toLocaleString('ru-RU');
    },
    rubrics(record) {
      if (record.rubrics && record.rubrics.length > 0) {
        return record.rubrics;
      }
      return false;
    },
    theme(record) {
      if (record.themes.length > 0) {
        return record.themes[0];
      }
      return false;
    },
    formatComponent(data) {
      if (data.url) {
        return {
          tag: 'a',
          href: data.url,
          title: data.name
        };
      }

      return {
        tag: 'span'
      };
    },
    onScroll() {
      this.checkScrollTargets();
    },
    sendReachGoal(fieldType, valueName, goalName) {
      const blockType = 'Контент';
      this.store.analyticsModule.sendNewReachGoal({
        blockType,
        fieldType,
        valueName,
        goalName
      });
    },
    goToDiscuss(event) {
      if (this.isBlockedUser === true) {
        event.preventDefault();
        this.openBlockedUserModal();
      }
    },
    onClickArchiveItem(record) {
      this.sendReachGoal('Переход на материал', record.id, 'clickArticle');
    },
    onClickFormat(format) {
      if (!format.url) {
        return;
      }

      this.sendReachGoal('Формат', format.name, 'clickArticleFormat');
    },
    onClickRubric(rubric) {
      this.sendReachGoal('Рубрика', rubric.name, 'clickArticleCategory');
    },
    onClickComments(record) {
      this.sendReachGoal('Комментарии к материалу', record.id, 'viewAllComments');
    },
    onClickTheme(theme) {
      this.sendReachGoal('Тема', theme.name, 'clickArticleTheme');
    },
    getTargetLink(path) {
      return isAbsoluteUrl(path) ? '_blank' : '_self';
    },
    checkIfInBookmarks(recordId) {
      return this.store.userModule.recordsWithBookmark.includes(recordId);
    },
    async changeBookmarkStatus(recordId) {
      if (this.isBookmarkProcessing === true) {
        return;
      }

      const bookmarkId = this.store.userModule.bookmarkIdsByRecord[recordId] || '';

      if (this.userInfo === null) {
        this.store.userModule.sendBookmarkReachGoal({ recordId, bookmarkId, isAuthorized: false });
        this.showAuthModal();
        return;
      }

      this.isBookmarkProcessing = true;

      try {
        await this.store.userModule.changeBookmarkStatus({
          regionId: this.regionId,
          profileId: this.userInfo.userId,
          recordId,
          bookmarkId,
          type: 'record'
        });

        this.store.userModule.sendBookmarkReachGoal({ recordId, bookmarkId });
      } catch (error) {
        this.openModal();
      } finally {
        this.isBookmarkProcessing = false;
      }
    },
    openModal() {
      if (this.isBookmarkError) {
        this.openBookmarkErrorModal();
        return;
      }

      this.openErrorModal();
    },
    openBookmarkErrorModal() {
      this.isBookmarkErrorModalOpened = true;
      this.isBookmarkErrorModalRendered = true;
    },
    openErrorModal() {
      this.isSomethingWrongModalOpen = true;
      this.isSomethingWrongModalRendered = true;
    },
    openBlockedUserModal() {
      this.isBlockedUserModalOpened = true;
      this.isBlockedUserModalRendered = true;
    },
    showAuthModal() {
      this.isAuthModalRendered = true;
      this.isAuthModalOpen = true;
    }
  }
};
</script>
<style lang="scss" module>
@import 'styles';

.searchRecordsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include on-min-resize($tablet) {
    padding: 0 $desktop-padding;
  }
}
</style>
