import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '../../mixins/inject-styles';

import styles from './jtn-ui-side-block-header.styles.scss?module';

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'JtnUiSideBlockHeader',
  props: {
    text: {
      type: String,
      default: ''
    },
    hasBorder: {
      type: Boolean,
      default: true
    },
    url: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    hasLogo: {
      type: Boolean,
      default: false
    },
    linkType: {
      type: String as () => 'blue' | 'white',
      default: 'blue'
    },
    counterLabel: {
      type: String,
      default: ''
    },
    isRedesign: {
      type: Boolean,
      default: false
    },
    titleTag: {
      type: String as () => 'h1' | 'div',
      default: 'div'
    },
    dataTestHeader: {
      type: String,
      default: ''
    }
  },
  render() {
    const attributes = {
      attrs: this.$attrs,
      on: this.$listeners
    };

    const TAG = this.titleTag;

    return (
      <div
        class={[
          styles.wrapper,
          this.hasBorder ? styles.border : '',
          this.isRedesign ? styles.redesign : ''
        ]}
      >
        <div class={styles.primaryLine} data-test={this.dataTestHeader}>
          {this.url ? (
            <a
              href={this.url}
              class={[styles.headerLink, styles[this.linkType]]}
              {...attributes}
            >
              {this.hasLogo && <span class={styles.logo}>{this.$slots.logo}</span>}

              <div class={styles.title}>
                <span domPropsInnerHTML={this.text} />
                {this.counterLabel && (
                  <span
                    class={styles.counterLabel}
                    domPropsInnerHTML={this.counterLabel}
                  />
                )}
              </div>
              {this.subtitle && (
                <span class={styles.subtitle} domPropsInnerHTML={this.subtitle} />
              )}

              {this.$slots.icon}
            </a>
          ) : (
            <div class={styles.headerWrapper} {...attributes}>
              {this.$slots.logo}

              <TAG class={styles.title} domPropsInnerHTML={this.text} />
              {this.subtitle && (
                <span class={styles.subtitle} domPropsInnerHTML={this.subtitle} />
              )}
            </div>
          )}

          <div class={styles.remark}>{this.$slots.remark}</div>
        </div>

        <div class={styles.subtext}>{this.$slots.subtext}</div>

        <div class={styles.secondaryLine}>{this.$slots.nav}</div>
      </div>
    );
  }
});
